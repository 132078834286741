<template>
  <div>
    <div>
      <b-modal
            v-model="showModal"
            id="modal-center"
            centered
            cancel-title="Ok!"
            ok-title="Go To History tab"
            class="justify-content-center"
            @ok="goToHistory"
            @cancel="onCloseModal"
            v-on:close="onCloseModal"
            no-close-on-backdrop
            >
          <div class="modal-text">
            <span class="modal-title">{{ $t("Remote") }}</span><br/>
            <span class="modal-description">
              {{ $t("From this page you can remotely control your equipment. You can swap, edit or set programs.") }}<br/><br/>
              {{ $t("Keep in mind that all changes made remotely will take instant effect in your equipment.") }}<br/><br/>
              {{ $t("You can monitor all actions on the History tab.") }}
            </span>
          </div>
    </b-modal>
    </div>
    <b-row class="match-height">
      <b-col cols="12" xl="12">
        <b-card no-body>
          <b-card-body>
            <div class="remote-top d-flex flex-column flex-xl-row align-items-xl-center align-items-start">
              <b-card-title class="text-remote mb-0">{{
                  device ? device.name || device.model_code  : ""
              }}</b-card-title>
              <div class="remote-right">
                <b-button
                  class="remote-btn"
                  variant="none"
                  data-id="01"
                  @click="gobacktodevice()"
                >
                  <feather-icon class="remote-icon" icon="CheckSquareIcon" />
                  <span class="align-middle">{{ $t("Fit for Use") }}</span>
                </b-button>
                <b-button
                  class="remote-btn"
                  variant="none"
                  data-id="02"
                  @click="gobacktodevice()"
                >
                  <feather-icon class="remote-icon" icon="CreditCardIcon" />
                  <span class="align-middle">{{ $t("Ambient") }}</span>
                </b-button>
                <b-button
                  class="remote-btn"
                  variant="none"
                  data-id="03"
                  @click="gobacktodevice()"
                >
                  <feather-icon class="remote-icon" icon="TrendingUpIcon" />
                  <span class="align-middle">{{$t("Performance")}}</span>
                </b-button>
                <b-button
                  class="remote-btn"
                  variant="none"
                  data-id="04"
                  @click="goToAlerts()"
                >
                  <feather-icon class="remote-icon" icon="BellIcon" />
                  <span class="align-middle">{{ $t("Alerts") }}</span>
                </b-button>
                <b-button
                  class="remote-btn"
                  variant="none"
                  data-id="05"
                  @click="goToReports()"
                >
                  <feather-icon class="remote-icon" icon="PieChartIcon" />
                  <span class="align-middle">{{ $t("Reports") }}</span>
                </b-button>
                <b-button
                  class="remote-btn mr-0"
                  variant="none"
                  data-id="06"
                  @click="gobacktodevice()"
                >
                  <feather-icon class="remote-icon" icon="CheckSquareIcon" />
                  <span class="align-middle">{{ $t("Details") }}</span>
                </b-button>
              </div>
              <div class="remote-info-wrapper relative d-flex">
                <p class="serial-number">
                  {{ $t("Serial") }}:
                  <span>{{ device.serial_number }}</span>
                </p>
                <p class="model-number">
                  {{ $t("Model") }}:
                  <span>{{ device.model_code }}</span>
                </p>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BModal
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    VueApexCharts,
    BModal
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      currentCommand: null,
      modalTitle: "",
    };
  },
  computed: {
    device() {
      return this.$store.state.devices.currentDevice;
    },
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    recipes() {
      return this.$store.state.recipes.recipes;
    },
    currentUserShowModals(){
      return this.$store.state.users.currentUserShowModals
    },
    currentUser(){
      return this.$store.state.users.currentUser;
    },
  },
  watch: {
    currentUser(newProp, oldProp){
      return this.verifyModal();
    },
  },
  methods: {
    gobacktodevice() {
      this.$router.push({
        name: "device",
        params: { id: this.$route.params.id },
      });
    },
    goToAlerts() {
      this.$router.push({
        name: "device-alerts",
        params: { id: this.$route.params.id },
      });
    },
    goToReports() {
      this.$router.push({
        name: "device-reports",
        params: { id: this.$route.params.id },
      });
    },
    getCurrentDevice() {
      this.$store.dispatch("devices/getSingleDevice", {
        context: this,
        id: this.$route.params.id,
      });
      this.getSingleDeviceRole()
    },
    getSingleDeviceRole(){
      this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
      });
    },
    goToHistory() {
      this.$router.push({
        name: "history",
      });
    },
    onCloseModal(){
      let data = {remote_page:true}   
      this.$store.dispatch("users/setPageView", { context: this, data, id:this.currentUserShowModals.id})
    },
    verifyModal(){
      this.$store.dispatch("users/getCurrentUserPages", { context: this})
      .then(() => {
        let currentUserShowModals = this.$store.state.users.currentUserShowModals
        if(currentUserShowModals){
          this.showModal = !currentUserShowModals.remote_page
        }
      })
    },
  },
  created() {
    setTimeout(() => {
      this.$store
        .dispatch("recipes/getRecipes", { context: this })
        .then(() => {
         return this.getCurrentDevice();
        })
    }, 500);
    this.verifyModal()
  },
};
</script>