<template>
  <div>
    <b-row class="match-height" v-if="!btnDisabled">
      <b-col cols="12" xl="12">
        <b-card no-body class="remote-listagem">
          <b-card-body>
            <div class="listagem-top">
              <span class="text-listagem"> {{ $t("Remote Actions") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-row class="justify-content-center">
              <b-col class="align-self-center mb-1" cols="12" xl="10">
                <b-row class="device-status-remote">
                  <b-col class="device-column-btn" cols="6" xl="3">
                    <b-button
                      :class="
                        isActive(
                          currentMetricGenericDevice.turned_on
                            ? currentMetricGenericDevice
                            : false
                        )
                      "
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      @click="openModalConfirm(turn_on_off)"
                      variant="none"
                      :disabled="btnDisabled"
                    >
                      <img src="@/assets/images/icons/off.png" alt="" />
                      <span>{{
                        currentMetricGenericDevice.turned_on
                          ?$t("Turn On")
                          :  $t("Turn Off")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col class="device-column-btn" cols="6" xl="3">
                    <b-button
                      :class="
                        isActive(
                          currentMetricGenericDevice.defrost_cycle_on
                            ? currentMetricGenericDevice
                            : false
                        )
                      "
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      @click="openModalConfirm(defrost)"
                      variant="none"
                      :disabled="btnDisabled || currentMetricGenericDevice.defrost_cycle_on"
                    >
                      <img
                        src="@/assets/images/icons/descongelacao.png"
                        alt=""
                      />
                      <span>{{
                        currentMetricGenericDevice.defrost_cycle_on
                          ? $t("Defrost running")
                          : $t("Defrost")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col class="device-column-btn" cols="6" xl="3">
                    <b-button
                      :class="
                        isActive(
                          currentMetricGenericDevice.illumination_on
                            ? currentMetricGenericDevice
                            : false
                        )
                      "
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      @click="openModalConfirm(turn_light_on_off)"
                      variant="none"
                      :disabled="btnDisabled"
                    >
                      <img
                        v-if="currentMetricGenericDevice.illumination_on"
                        src="@/assets/images/icons/light-on.png"
                        alt=""
                      />
                      <img
                        v-else
                        src="@/assets/images/icons/light.png"
                        alt=""
                      />
                      <span>{{
                        currentMetricGenericDevice.illumination_on
                          ? $t("Light On")
                          : $t("Turn On")
                      }}</span>
                    </b-button>
                  </b-col>
                  <b-col class="device-column-btn" cols="6" xl="3">
                    <b-button
                      :class="
                        isActive(
                          currentMetricGenericDevice
                            .keyboard_controller_blocked
                            ? currentMetricGenericDevice
                            : false
                        )
                      "
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      @click="openModalConfirm(lock_unlock)"
                      variant="none"
                      :disabled="btnDisabled"
                    >
                      <img src="@/assets/images/icons/lock.png" alt="" />
                      <span>{{
                        currentMetricGenericDevice
                          .keyboard_controller_blocked
                          ? $t("Keyboard locked")
                          : $t("Lock")
                      }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!--CONFIRMATION MODAL-->
    <b-modal
      id="modal-center-confirmation"
      ok-only
      centered
      ok-title="Confirmar"
      class="justify-content-center"
      @ok="submitCommand"
    >
      <span class="modal-title">{{ "Confirm" }} ({{ action }})</span>
      <b-row>
        <b-col cols="12" class="mt-1">
          <p>{{ $t("Are you sure you want to continue?") }}</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import RemoteFoodCarousel from "./RemoteFoodCarousel.vue";
import RemoteProgramasTemp from "./RemoteProgramasTemp.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    RemoteFoodCarousel,
    RemoteProgramasTemp,
    BModal,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      searchTerm: "",
      turn_on_off: 1,
      defrost: 2,
      turn_light_on_off: 3,
      lock_unlock: 4,
      action: "",
      hasOptions: false,
      selected_action: "",
      register: "",
      DIXEL_EVCO_REGISTER: {
        "DIXEL":{
          "turnOn_Off":512,
          "lightOn_Off":538,
          "lock_Unlock":515,
          "defrost":513
        },
        "EVCO":{
          "turnOn_Off":2832,
          "lightOn_Off":3079,
          "lock_Unlock":1808,
          "defrost":3080
        }
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.users.currentUser;
    },
    currentDevice() {
      return this.$store.state.devices.currentDevice;
    },
    currentDevicerRoles()
    {
      return this.$store.state.devices.currentDevicerRoles
    },
    btnDisabled() {
      if (this.$roles.isAdmin(this.user) || this.$roles.isClientRemote(this.user, this.currentDevicerRoles) || this.$roles.isTechnicianRemote(this.user, this.currentDevicerRoles) )
      {
        return false;
      } 
      else{
        return true;
      } 
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
  },
  methods: {
    submitCommand() {
      let data = this.createCmd();
      const requestEndpoint = `${process.env.VUE_APP_API_URL}api/commands/`;
      this.$http
        .post(requestEndpoint, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Command Sent",
              icon: "CommandIcon",
              variant: "success",
            },
          });
          setTimeout(this.getSingleDevice(), 1000);
          setTimeout(this.getSingleDevice(), 3000);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.data,
            },
          });
          setTimeout(this.getSingleDevice(), 1000);
        });
    },
    openModalConfirm(data) {
      this.selected_action = "";
      this.action = "";
      this.register = "";
      switch (data) {
        case this.turn_on_off:
          this.action = this.currentMetricGenericDevice.turned_on
            ? this.$t("Turn On")
            : this.$t("Turn Off");
          this.hasOptions = true;
          this.register = this.DIXEL_EVCO_REGISTER[this.currentDevice.controller]["turnOn_Off"];
          this.selected_action = this.currentMetricGenericDevice.turned_on
            ? 0
            : 1;
          break;

        case this.defrost:
          this.hasOptions = false;
          this.action = "Defrost";
          this.selected_action = 1;
          this.register = this.DIXEL_EVCO_REGISTER[this.currentDevice.controller]["defrost"];
          break;

        case this.turn_light_on_off:
          this.action = this.currentMetricGenericDevice.illumination_on
            ? "Turn Off Light"
            : "Turn On Light";
          this.hasOptions = true;
          this.register = this.DIXEL_EVCO_REGISTER[this.currentDevice.controller]["lightOn_Off"];
          this.selected_action = this.currentMetricGenericDevice
            .illumination_on
            ? 0
            : 1;
          break;

        case this.lock_unlock:
          this.action = this.currentMetricGenericDevice
            .keyboard_controller_blocked
            ? this.$t("Unlock")
            : this.$t("Lock");
          this.hasOptions = true;
          this.register = this.DIXEL_EVCO_REGISTER[this.currentDevice.controller]["lock_Unlock"];
          this.selected_action = this.currentMetricGenericDevice
            .keyboard_controller_blocked
            ? 0
            : 1;
          break;

        default:
          this.selected_action = "";
          this.action = "";
          this.register = "";
          break;
      }
      this.$bvModal.show("modal-center-confirmation");
    },
    createCmd() {
      let data = {}
      if (this.currentDevice.controller == "EVCO"){
        data = {
        command: {
          CMD: 1,
          Data: {
            Register: this.register,
            Value: this.selected_action,
          },
        },
        device: this.currentDevice.mac_number,
        description: this.action,
      }
    }
      else if (this.currentDevice.controller == "DIXEL"){
        data = {
        command: {
          CMD: 1,
          Data: {
            Register: this.register,
            Value: this.selected_action,
            Mode: 5
          },
        },
        device: this.currentDevice.mac_number,
        description: this.action,
      }
      };
      console.log(data)
      return data;
    },
    isActive(isActivebutton) {
      if (isActivebutton) return "devices-btn active";
      else return "devices-btn";
    },
    getSingleDevice() {
      this.$store.dispatch("devices/getSingleDevice", {
        context: this,
        id: this.$route.params.id,
      });
      this.getSingleDeviceRole()
      this.getGenericMetric()
    },
    getSingleDeviceRole(){
      this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    },
    getGenericMetric(){
      this.$store.dispatch("devices/getGenericDevicelMetric", {
      context: this,
      id: this.$route.params.id,
    });
    }
  },
  beforeCreate() {
    this.getSingleDevice();
  },
};
</script>